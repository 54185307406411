import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const Drogas = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="In Search of Drogas" />
        <meta name="twitter:image" content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="article">
        <Link to="/shoyaright">
        <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p> In Search of Drogas </p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Jan. 16, 2020) </p>
        </div>
        <div className="articleBody">
        <p>
        <span className="highlight">Disclosures</span>: Is the artist a childhood friend of mine? Yes. Does he, despite being a grown ass man, still not like eating vegetables? Yes.
        </p>
        <p>
        At this point, South Philadelphia's <a href="https://www.instagram.com/wewantdrogas/" target="_blank" rel="noopener noreferrer ">WEWANTDROGAS</a> might as well change his moniker to WEWANTTHETAPE to better reflect what's really going on in the hearts and minds of his constituents. 
        </p>
        <p>
        After an eventful summer (by his standards), namely two singles dropped within the space of a month -- "<a href="https://soundcloud.com/wewantdrogas/dead-situations" target="_blank" rel="noopener noreferrer">Dead Situations</a>" in May, followed by  "<a href="https://www.youtube.com/watch?v=0Li_PIrMIc4&feature=youtu.be" target="_blank" rel="noopener noreferrer">Dark Blue</a>", which premiered on Lyrical Lemonade in June (both produced by <a href="https://www.instagram.com/_mattifree/" target="_blank" rel="noopener noreferrer">Matti Free</a>) -- 
        it seems like DROGAS might have abandoned that momentum and gone back into his cave for the fall. If I had to guess, he's somewhere with longtime collaborators <a href="https://www.instagram.com/saeedferguson/?hl=en" target="_blank" rel="noopener noreferrer">Saeed Ferguson</a> and <a href="https://www.instagram.com/frederickscott_/" target="_blank" rel="noopener noreferrer">Frederick Scott</a> poring closely over some new press materials. Or maybe he's ordering chicken strips at the papi store to take back to the studio. 
        </p>
        <p>
        Either way I still gotta ask…………...where's the tape? 
        </p>
        <p>
        Of course, I could just send a text asking him that exact question (something I've done before), but honestly, the point of this article is closer to peer pressure than reporting. 
        </p>
        <p>
        After all, Philly is in the middle of a musical renaissance as I write this.
If you forget international acts like Meek Mill and Lil Uzi Vert, consider breakthrough artists like
            {' '}
            <a href="https://www.youtube.com/watch?v=EOTebhPy04g" target="_blank" rel="noopener noreferrer">Tierra Whack</a>
            {' '}
and
            {' '}
            <a href="https://www.youtube.com/watch?v=lMQK7uSMhhI" target="_blank" rel="noopener noreferrer">Matt Ox</a>
, the near-juggernaut producers of
            {' '}
            <a href="https://www.thefader.com/2018/11/27/working-on-dying-lil-uzi-vert-interview" target="_blank" rel="noopener noreferrer">Working On Dying</a>
, and a fresh crop in
            {' '}
            <a href="https://www.youtube.com/watch?v=QkohfaMEKkA" target="_blank" rel="noopener noreferrer">Sim Santana</a>
,
            {' '}
            <a href="https://www.youtube.com/watch?v=jnUSozaJgb8" target="_blank" rel="noopener noreferrer">Bry Greatah</a>
, and
            {' '}
            <a href="https://www.youtube.com/watch?v=HX92VW_R4IM" target="_blank" rel="noopener noreferrer">DrewBoy</a>
.
            {' '}
I would, of course, be remiss not to bring in Uptown mainstay
            {' '}
            <a href="https://www.youtube.com/watch?v=LuUGEdZ40QI" target="_blank" rel="noopener noreferrer">Kur</a>
, who I&#39;ll think of as both a rookie and a vet until he gets the shine he deserves (not to mention that the streets need it to be
            {' '}
            <a href="https://soundcloud.com/babycom/poppin-bitch-2" target="_blank" rel="noopener noreferrer">baby time</a>
            {' '}
immediately, and the list goes on).
        </p>
        
        <p>
        So after two undeniably great tracks, what's stopping the Smallside Savior from striking fertile ground? When will he decide to take the place he so clearly deserves within the city's colorful landscape and beyond? I'll borrow my answer from "Dead Situations" (1:15): "Shit I wish I knew myself // I wish I knew myself".
        </p>
        <p>
        If you share my concern, I recommend you join me in harassing him on social media.
        </p>
        <p>   
        All that said, I look forward to some great music from him soon, and so should you.
          </p>
          <p className="plug">If you enjoyed this article, consider <a href="https://www.patreon.com/join/shoyaright?">becoming a patron</a> or making a <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">one-time pledge</a>. Support corporate journalism!</p>
        </div>
        <br />
        <br />
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020</p>
        </div>
      </div>
    </div>

  );
};

export default Drogas;
